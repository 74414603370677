import React, { useCallback, useEffect, useRef, useState } from "react";

const SearchField = ({
	initialValue = "",
	onSearch,
	className = "",
	placeholder = "Search",
}) => {
	const [searchKey, setSearchKey] = useState(initialValue);
	const debounceTimerRef = useRef(null)

	// Cleanup timeout on unmount
	useEffect(() => {
		return () => {
			if (debounceTimerRef.current) {
				clearTimeout(debounceTimerRef.current)
			}
		}
	}, [])

	const debouncedSearch = useCallback((value) => {
		// Clear previous timeout
		if (debounceTimerRef.current) {
			clearTimeout(debounceTimerRef.current)
		}

		// Set new timeouot
		debounceTimerRef.current = setTimeout(() => {
			if (onSearch) {
				onSearch(value)
			}
		}, 500)

	}, [onSearch])

	const handleOnChange = useCallback((event) => {
		const value = event.target.value
		setSearchKey(value)
		debouncedSearch(value)
	}, [debouncedSearch])

	return (
		<div className={`keyword-search ${className}`}>
			<div className="keyword-search-container">
				<input
					value={searchKey}
					placeholder={placeholder}
					type="text"
					className="form-control"
					onChange={handleOnChange} 
         			aria-label={placeholder}
				/>
				<i className="icon icon-search" />
			</div>
		</div>
	);
};
export default SearchField;
