import fetchHandler from "../../utils/fetchHandler";
import { PROSPECTING } from "../types";

export const getRealtorCommunicationFeed = (
	values,
	successHandler,
	errorHandler
) => {
	let endPoint = `prospecting/get-realtor-communication-feed?realtorId=${values.realtorId}`;
	if (values.isPropertyPage) {
		endPoint =
			values.communicationFeedType === 2
				? (endPoint = `prospecting/get-property-owner-communication-feed?ownerId=${values.propertyOwnerId}&propertyId=${values.propertyId}`)
				: `prospecting/get-property-realtor-communication-feed?realtorId=${values.realtorId}&propertyId=${values.propertyId}`;
	}
	const fetchOptions = {
		url: endPoint,
		method: "GET",
		secure: true,
		actionType: PROSPECTING.GET_REALTOR_COMMUNICATION_FEED,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const sendManualProspecting = (values, successHandler, errorHandler) => {
	let endPoint = `prospecting/send-manual-prospecting-email`;
	if (values.templateType === "sms")
		endPoint = `prospecting/send-manual-prospecting-sms`;
	if (values.templateType === "mailer")
		endPoint = `prospecting/send-manual-prospecting-post-card`;
	const fetchOptions = {
		url: endPoint,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: PROSPECTING.SEND_MANUAL_PROSPECTING,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getCommunicationFeedDetails = (
	values,
	successHandler,
	errorHandler
) => {
	console.log("getCommunicationFeedDetails", values);
	const fetchOptions = {
		url: `prospecting/get-communication-feed-details?prospecttype=${
			values.communicationFeedType === 1 || !values.communicationFeedType
				? 1
				: 2
		}&prospectingLogId=${values.prospectingLogId}`,
		method: "GET",
		secure: true,
		actionType: PROSPECTING.GET_COMMUNICATION_FEED_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
