import { get } from "lodash";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getFormValues, reduxForm } from "redux-form";
import { userTypes } from "../../constant/userManagement";
import { Pagination, Table } from "../../partials/table/table";
import {
  clearProperty,
  getProperties,
  getPropertyChildMarkets,
  getPropertySavedFilters,
  getPropertyStatuses,
  getPropertyZipcodes,
  savePropertyFilters,
  setPropertyFilter,
  toggleMainLoader,
  updatePropertySettings,
} from "../../store/actions";
import { errorMessage } from "../../utils/errorMessage";
import FilterForm from "../../utils/filterForm";
import SidePanel from "../../utils/sidePanel";
import TableLoader from "../../utils/tableLoader";
import Button from "../../utils/uiElements";
import AppliedFiltersList from "./AppliedFiltersList";
import {
  columns as columnsGenerator,
  filtersArray as filtersArrayGenerator,
  getinitValuesFromRedux,
  mapfilterValues,
} from "./helper";
import "./properties.scss";
import TopHeadBar from "./TopHeadBar";


const sortBy = "dateAdded";
const sortDir = "desc";

/**
 * Parent component for property listing.
 *
 * @param {props} object
 */
const PropertyListing = (props) => {
  const {
    location,
    history,
    getProperties,
    updatePropertySettings,
    propertyList,
    recordsTotal,
    userDetails,
    toggleMainLoader,
    propertyZipcode,
    getPropertyZipcodes,
    getPropertyStatuses,
    propertyStatuses,
    getPropertyChildMarkets,
    propertyChildMarkets,
    filterFormValues,
    propertySavedFilters,
    getPropertySavedFilters,
    savePropertyFilters,
    change,
    filterObject,
    setFilters,
    clearProperty,
  } = props;
  const [isFromPropertyView, setIsFromPropertyView] = useState(location.state?.fromPropertyView)
  /**
   * Parent component for property listing.
   *
   * @param {props} object
   */
  const realtorId = queryString.parse(location.search).realtorId;
  const onSelect = (row) => {
    realtorId
      ? history.push(`/listings/${row.propertyId}/view?realtorId=${realtorId}`)
      : history.push(`/listings/${row.propertyId}/view`);
  };


  const [filterPanelDisplay, showFilterPanel] = useState(false);
  const [dataReload, setDataReload] = useState(false);
  const [selectedSavedValue, setSelectedSavedValue] = useState();

  /* Since the FilterForm component is unmounting on sibmit , form value won't be avilablle in redux form
   * On the remounting event we want to rest the FilterForm with the prev selected value
   * Using this this sate we are preserving the form value after the FilterForm unmount
   */
  const [filterSelectedValues, setFilterSelectedValues] = useState();
  //This state is used to handle the react DatePicker component value
  const [selectedDate, setSelectedDate] = useState({});
  const [selectedPendingDate, setSelectedPendingDate] = useState();
  //Main filter object state which we are using to intract with webservice

  const onApplyFilter = (formValues = null) => {
    showFilterPanel(false);
    setFilterSelectedValues({
      ...formValues,
      saveFilter: false,
      filterName: "",
    });
    //Mapping form values to filtter object
    const mappedfilterValues = mapfilterValues(formValues);

    setFilters({
      sortColumn: filterObject.sortColumn,
      sortDirection: filterObject.sortDirection,
      pageIndex: 1,
      pageSize: 10,
      searchKey: "",
      filters: mappedfilterValues,
    });
    const successHandler = (event) => {
      getPropertySavedFilters();
    };
    //Save the filter if marked
    if (formValues.saveFilter && mappedfilterValues.length) {
      savePropertyFilters(
        {
          filterName: formValues.filterName,
          filters: mappedfilterValues,
        },
        successHandler
      );
    }
  };

  const loggedInAs = (type) => {
    return userDetails.userType === userTypes[type];
  };

  const columns = columnsGenerator({
    onSelect,
    updatePropertySettings,
    userDetails,
    toggleMainLoader,
    loggedInAs,
    dataReload,
    setDataReload,
  });

  //Api
  useEffect(() => {
    clearProperty();
  }, []);
  useEffect(() => {
    if (isFromPropertyView && propertyList) {
      setIsFromPropertyView(false)
      toggleMainLoader(false);
      return;
    }

    const successHandler = (event) => {
      toggleMainLoader(false);
    };
    const errorHandler = (event) => {
      errorMessage(event);
      toggleMainLoader(false);
    };
    if (filterObject && filterObject.pageIndex) {
      setIsFromPropertyView(false)
      toggleMainLoader(true);
      if (realtorId) {
        getProperties(
          {
            ...filterObject,
            filters: [
              {
                key: "realtor",
                value: realtorId,
                label: "Realtor",
              },
            ],
          },
          successHandler,
          errorHandler
        );
      } else {
        const backendFilterObject = { ...filterObject };
        if (
          filterObject &&
          filterObject.filters &&
          filterObject.filters.length
        ) {
          backendFilterObject.filters = filterObject.filters.map((object) => {
            return {
              key: object.key,
              value: object.value,
            };
          });
        }
        getProperties(backendFilterObject, successHandler, errorHandler);
      }
    }
  }, [
    filterObject,
    getProperties,
    toggleMainLoader,
    realtorId,
    dataReload,
    setFilters,
  ]);

  //initializing form options
  useEffect(() => {
    if (!realtorId) {
      getPropertyZipcodes();
      getPropertyStatuses();
      getPropertyChildMarkets();
      getPropertySavedFilters();
    }
  }, [
    realtorId,
    getPropertyZipcodes,
    getPropertyStatuses,
    getPropertyChildMarkets,
    getPropertySavedFilters,
  ]);

  useEffect(() => {
    const reduxFilter = getinitValuesFromRedux(filterObject.filters);
    setFilterSelectedValues(reduxFilter);
  }, [filterObject]);

  const onTableSort = (element) => {
    if (
      element.by !== filterObject.sortColumn ||
      element.order !== filterObject.sortDirection
    ) {
      setFilters({
        ...filterObject,
        sortColumn: element.by,
        sortDirection: element.order,
        pageIndex: 1,
      });
    }
  };

  const onPaginate = (element) => {
    setFilters({
      ...filterObject,
      pageIndex: element,
    });
  };

  return (
    <>
      <section className="moovsoon-page-container">
        <div className="moovsoon-page-body">
          {
            realtorId &&
            <Button
              type="button"
              value="< Back"
              className="btn btn-default btn-sm mr-2 mb-3"
              onClick={() => history.push(`/realtors/${realtorId}/view`, { fromListingWithRealtorView: true })}
            />
          }
          <TopHeadBar
            {...{
              filterObject,
              showFilterPanel,
              toggleMainLoader,
              setFilters,
              propertySavedFilters,
              setFilterSelectedValues,
              setSelectedDate,
              realtorId,
              selectedSavedValue,
            }}
          />
          {
            !realtorId &&
            <AppliedFiltersList
              {...{
                filterObject,
                setFilters,
                setFilterSelectedValues,
                setSelectedDate,
                filterSelectedValues,
                selectedDate,
                propertyStatuses,
                propertyChildMarkets,
                change,
                setSelectedSavedValue,
                selectedPendingDate,
                setSelectedPendingDate,
              }}
            />
          }
          {propertyList && propertyList.length ? (
            <div>
              <Table
                columns={columns}
                data={propertyList}
                onSort={onTableSort}
                sortBy={filterObject.sortColumn}
                sortDir={filterObject.sortDirection}
              />
              <Pagination
                total={recordsTotal}
                count={filterObject.pageSize}
                page={filterObject.pageIndex}
                onChange={onPaginate}
              />
            </div>
          ) : null}
          <TableLoader list={propertyList} />
        </div>
        <SidePanel
          show={filterPanelDisplay}
          onHide={() => showFilterPanel(false)}
          component={FilterForm}
          componentProps={{
            onApplyFilter,
            onCancelFilter: () => showFilterPanel(false),
            filtersArray: filtersArrayGenerator({
              propertyZipcode,
              propertyStatuses,
              propertyChildMarkets,
              filtersSelected: filterObject.filters,
              selectedDate,
              setSelectedDate,
              filterFormValues,
              loggedInAs,
              selectedPendingDate,
              setSelectedPendingDate,
            }),
            initialValues: filterSelectedValues || null,
          }}
        />
      </section>
    </>
  );
};

//KAN-52: reference
const mapStateToProps = (state) => {
  return {
    propertyList: get(state, "Property.propertyList.data", null),
    filterObject: get(state, "Property.propertyFilter", {
      sortColumn: sortBy,
      sortDirection: sortDir,
      pageIndex: 1,
      pageSize: 10,
      searchKey: "",
      filters: [{ key: "status", value: "1", label: "Status" }],
    }),
    recordsTotal: get(state, "Property.propertyList.recordsTotal", 0),
    propertyZipcode: get(state, "Property.propertyZipcodes", []),
    propertyStatuses: get(state, "Property.propertyStatuses", []),
    propertyChildMarkets: get(state, "Property.propertyChildMarkets", []),
    propertySavedFilters: get(state, "Property.propertySavedFilters", []),
    userDetails: get(state, "Auth.userDetails", {}),
    filterFormValues: getFormValues("FilterForm")(state),
  };
};

const mapDispatchToProps = {
  getProperties,
  toggleMainLoader,
  updatePropertySettings,
  getPropertyZipcodes,
  getPropertyStatuses,
  getPropertyChildMarkets,
  getPropertySavedFilters,
  savePropertyFilters,
  clearProperty,
  setFilters: setPropertyFilter,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "SavedFilterForm",
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(PropertyListing)
);
