import React from "react";
import { userTypes } from "../../../constant/userManagement";
import { Currency } from "../../../utils/formatter";
import Tooltip from "../../../utils/tooltip";

const renderMarkets = (marketsString) => {
  const list = marketsString && marketsString.split("||");
  return list ? (
    list.length === 1 ? (
      <div>{list[0]}</div>
    ) : (
      <div className="">
        <Tooltip
          placement="left"
          message={list.map((item, index) => (
            <div key={index} className="market-list-item">
              {item}
            </div>
          ))}
          component={
            <div className="launcher pointer ">{list.length} items</div>
          }
        />
      </div>
    )
  ) : null;
};

export const columns = ({ onSelect, userDetails, withRank = false }) => {
  const isUserAdmin = userDetails && userDetails.userType === userTypes.AD
  return [
    {
      name: "Name",
      accessor: "fullName",
      className: "col-realtor-name",
      headerClassName: "col-realtor-name-header",
      render: (row) => (
        <div
          className="text-primary pointer ellipsis"
          onClick={() => onSelect(row)}
        >
          {row.fullName}
        </div>
      ),
      sortable: false
    },
    {
      name: "Office Name",
      accessor: "officeName",
      className: "col-realtor-office-name",
      headerClassName: "col-realtor-office-name-header",
      sortable: false,
    },
    {
      name: "Rank",
      accessor: "ranking",
      className: "col-realtor-rank",
      headerClassName: "col-realtor-rank-header",
      sortable: withRank,
      hide: isUserAdmin,
    },
    {
      name: "Total In Process Listing",
      accessor: "totalListing",
      className: "col-realtor-total-listing",
      headerClassName: "col-realtor-total-listing-header break-word",
      hide: isUserAdmin,
      sortable: false,
    },
    {
      name: "Referral Opportunities (R12)",
      accessor: "referralOpertunity",
      className: "col-realtor-referral-opportunities",
      headerClassName: "col-realtor-referral-opportunities-header break-word",
      sortable: false,
      hide: isUserAdmin,
    },
    {
      name: "Total listing",
      accessor: "totalVolume",
      className: "col-realtor-TotalVolume",
      headerClassName: "col-realtor-TotalVolume-header break-word",
      hide: isUserAdmin,
      sortable: true,
      defaultSortBy: 'desc'
    },
    {
      name: "Total Value",
      accessor: "totalPrice",
      render: (row) => (
        <div className="">
          <Currency amount={row.totalPrice} currency="USD" />
        </div>
      ),
      className: "col-realtor-TotalPrice",
      headerClassName: "col-realtor-TotalPrice-header break-word",
      sortable: true,
      hide: isUserAdmin,
      defaultSortBy: 'desc'
    },
    {
      name: "Markets",
      accessor: "markets",
      className: "col-realtor-markets",
      headerClassName: "col-realtor-markets-header",
      render: (row) => renderMarkets(row.markets),
      sortable: false,
      hide: true,
    },
  ];
};
