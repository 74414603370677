import { get } from "lodash";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import { change, getFormValues } from "redux-form";
import RankImage from "../../../assets/rank-yellow.png";
import ProgressBar from "../../../utils/progressBar";
import "../realtors.scss";
import RealtorListingCoverage from "./realtorListingCoverage";

import { userTypes } from "../../../constant/userManagement";
import SidePanel from "../../../utils/sidePanel";
import { Button } from "../../../utils/uiElements";
import CommunicationFeed from "./communicationFeed";
import EmailPanel from "./EmailPanel";
import MailerPanel from "./MailerPanel";
import SMSPanel from "./SMSPanel";

import {
	getRealtor,
	getRealtorCommunicationFeed,
	getRealtorEditData,
	getRealtorListingCoverage,
	getRealtorMarketList,
	getTemplateCompanyDetails,
	sendManualProspecting,
	setPropertyFilter,
	settingsToggleMainLoader,
	toggleMainLoader,
	updateRealtorData,
} from "../../../store/actions";

import EditPanel from "../edit/EditPanel";

import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";

const initialStates = {
	subMarketType: null,
	marketRefId: null,
	realtorMarketList: [],
	reloadCommunicationFeed: false,
	selectedMarket: { label: "All", type: 0, value: null },
};

const reducer = (state, action) => {
	return { ...state, ...action };
};

const RealtorDetails = ({
	history,
	match,
	toggleMainLoader,
	userDetails,
	getRealtor,
	realtorDetails,
	getRealtorListingCoverage,
	realtorListingCoverage,
	getRealtorMarketList,
	getRealtorCommunicationFeed,
	emailTemplateformValues,
	mailerTemplateformValues,
	getTemplateCompanyDetails,
	sendManualProspecting,
	setPropertyFilter,
	getRealtorEditData,
	updateRealtorData,
	settingsToggleMainLoader,
}) => {
	const [state, dispatch] = useReducer(reducer, initialStates);
	const { params = null } = match;
	const [realtorEditData, setRealtorEditData] = useState({});
	const [reloadDetails, setReloadDetails] = useState(false);
	const realtorDetailsExists = realtorDetails && realtorDetails.realtorId === params.id
	const realtorListingCoverageExists = realtorListingCoverage && realtorListingCoverage.realtorId === params.id


	const {
		subMarketType,
		marketRefId,
		realtorMarketList,
		selectedMarket,
		showMailPanel,
		showPostCardPanel,
		showSMSPanel,
		templateCompanyDetails,
		reloadCommunicationFeed,
		showEditPanel,
	} = state;

	const loggedInAs = useCallback(
		(type) => {
			return userDetails.userType === userTypes[type];
		},
		[userDetails]
	);

	useEffect(() => {
		if (userDetails) {
			dispatch({
				subMarketType: loggedInAs("BO") ? 2 : loggedInAs("SM") ? 1 : 0,
			});
		}
	}, [userDetails, loggedInAs]);

	useEffect(() => {
		const { params = null } = match;

		if (!params || !params.id) return

		const successHandler = (e) => {
			dispatch({ realtorDetails: e.result });
		};

		if (realtorDetailsExists) return

		const getRealtorRequest = getRealtor(params.id, successHandler, null);

		return () => {
			if (getRealtorRequest.abort) {
				getRealtorRequest.abort()
			}
		}
	}, [getRealtor, match, reloadDetails, realtorDetailsExists]);

	useEffect(() => {
		let fetchRequests = [];
		if (
			subMarketType != null &&
			realtorDetails &&
			realtorDetails.realtorId && !realtorListingCoverageExists && realtorDetailsExists
		) {
			const coverageRequest = getRealtorListingCoverage(
				{
					realtorId: realtorDetails.realtorId,
					subMarketType,
					marketRefId,
				},
				(e) => {
					dispatch({
						realtorListingCoverage: e.result,
					})
				},
			);


			const marketListRequest = getRealtorMarketList(
				{
					realtorId: realtorDetails.realtorId,
				},
				(e) => {
					dispatch({
						realtorMarketList: [
							{ label: "All", type: subMarketType, value: null },
							...e.result,
						],
					})
				},
			);


			if (coverageRequest.abort) fetchRequests.push(coverageRequest.abort);
			if (marketListRequest.abort) fetchRequests.push(marketListRequest.abort);
		}


		return () => {
			fetchRequests.forEach(abort => abort());
		};
	}, [
		realtorDetailsExists,
		realtorListingCoverageExists,
		realtorDetails,
		subMarketType,
		marketRefId,
	]);

	useEffect(() => {
		if (typeof loggedInAs === "function" && !loggedInAs("AD")) {
			const successHandler = (event) => {
				event &&
					event.result &&
					dispatch({ templateCompanyDetails: event.result });
			};
			getTemplateCompanyDetails(successHandler);
		}
	}, [getTemplateCompanyDetails, loggedInAs]);

	const notAvailableSpan = (
		<span className="text-gray text-sm">Not available</span>
	);

	const editData = () => {
		settingsToggleMainLoader(true);
		getRealtorEditData(
			realtorDetails.realtorId,
			(event) => {
				setRealtorEditData(event.result);
				settingsToggleMainLoader(false);
				dispatch({ showEditPanel: true });
			},
			() => {
				settingsToggleMainLoader(false);
			}
		);
	};

	const onEditSubmit = (data) => {
		settingsToggleMainLoader(true);
		updateRealtorData(
			data,
			(event) => {
				settingsToggleMainLoader(false);
				dispatch({ showEditPanel: false });
				successMessage(8001);
				setReloadDetails(!reloadDetails);
			},
			(event) => {
				settingsToggleMainLoader(false);
				errorMessage(event);
			}
		);
	};
	const onCancel = () => {
		dispatch({ showEditPanel: false });
	};

	return (
		<section className="moovsoon-page-container pt-0">
			<div className="moovsoon-page-body">
				<button
					className="btn btn-default btn-sm mr-2 mb-3"
					onClick={() => {
						const link =
							params.source && params.source !== ""
								? `/listings/${params.source}/view`
								: "/realtors";
						history.push(link, { fromRealtorView: true });
					}}>
					<span className="text-primary">&lt;</span> Back
				</button>
				{realtorDetails && realtorDetailsExists ? (
					<div className="row mb-4 basic-details-section">
						<div className="col-7">
							<div className="card card-primary px-4 pt-3 pb-4 h-100">
								{typeof loggedInAs === "function" &&
									!loggedInAs("AD") ? (
									<div className="d-flex justify-content-end">
										{realtorDetails.email ? (
											<Button
												type="button"
												onClick={() => {
													dispatch({
														showMailPanel: true,
													});
												}}
												className="btn btn-cell btn-light ml-2"
												value={
													<i className="icon icon-inbox text-gray" />
												}
												tooltip="Send Email"
											/>
										) : null}
										{realtorDetails.mobile1 ? (
											<Button
												type="button"
												onClick={() => {
													dispatch({
														showSMSPanel: true,
													});
												}}
												className="btn btn-cell btn-light ml-2"
												value={
													<i className="icon icon-sms text-gray" />
												}
												tooltip="Send SMS"
											/>
										) : null}

										{realtorDetails.officeAddress1 ||
											realtorDetails.officeAddress2 ? (
											<Button
												type="button"
												onClick={() => {
													dispatch({
														showPostCardPanel: true,
													});
												}}
												className="btn btn-cell btn-light ml-2"
												value={
													<i className="icon icon-wallet text-gray" />
												}
												tooltip="Send Mailer"
											/>
										) : null}
									</div>
								) : null}

								{typeof loggedInAs === "function" &&
									loggedInAs("AD") ? (
									<div className="d-flex ">
										<div className="justify-content-start mr-auto">
											<h2>{realtorDetails.name}</h2>
										</div>
										<div className="justify-content-end">
											<button
												type="button"
												className="btn btn-light ml-2 justify-content-end"
												onClick={() => {
													editData();
												}}>
												<i className="icon icon-edit" />
												<span className="ml-2">
													Edit
												</span>
											</button>
										</div>
									</div>
								) : null}

								{typeof loggedInAs === "function" &&
									!loggedInAs("AD") ? (
									<h2>{realtorDetails.name}</h2>
								) : null}
								<div className="d-flex align-items-center mb-2">
									<i className="text-gray icon icon-mail text-lg" />
									<span className="ml-2">
										{realtorDetails.email
											? realtorDetails.email
											: notAvailableSpan}
									</span>
								</div>
								<div className="d-flex">
									<div className="flex-fill mr-3 d-flex align-items-center">
										<i className="text-gray icon icon-phone text-lg" />
										<span className="ml-2">
											{realtorDetails.mobile1
												? realtorDetails.mobile1
												: notAvailableSpan}
										</span>
									</div>
									<div className="d-flex align-items-center">
										<img
											className="mr-2 rank-icon"
											src={RankImage}
											alt="rank"
										/>
										<span className="mr-2">
											Realtor Rank
										</span>
										<h2 className="text-lg m-0">
											{realtorDetails.realtorRank}
										</h2>
									</div>
								</div>
								<div className="bb-1 pt-4"></div>
								<h3 className="color-1 text-uppercase py-2 my-2">
									Company Details
								</h3>
								<h2 className="d-flex align-items-center">
									<span className="btn-cell btn-default btn-xl pointer-none mr-2 d-flex align-items-center justify-content-center">
										<i className="icon icon-building text-lsm" />
									</span>
									<div>{realtorDetails.officeName}</div>
								</h2>
								<div className="row align-items-start">
									<div className="col-6 form-group mb-0">
										<div className="form-value">
											{realtorDetails.officeAddress1 ||
												realtorDetails.officeAddress2
												? `${realtorDetails.officeAddress1
												}
											${realtorDetails.officeAddress2 ? `, ${realtorDetails.officeAddress2}` : ""}`
												: "No Data"}
										</div>
										<div className="form-label">
											Address
										</div>
									</div>
									<div className="col-6 form-group mb-0">
										<div className="form-value">
											{realtorDetails.officePhone1
												? realtorDetails.officePhone1
												: "No Data"}
										</div>
										<div className="form-label">
											Phone Number
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-5">
							<div className="row h-100">
								<div className="col-6 pb-4 h-50">
									<div className="card card-primary p-4 mb-3 h-100 d-flex justify-content-end image-bg-card-1">
										<h2>
											{realtorDetails.referralOpertunity}
										</h2>
										<div>
											Referral
											<br />
											opportunities (R12)
										</div>
									</div>
								</div>
								<div className="col-6 pb-4 h-50">
									<div className="card card-primary p-4 mb-3 h-100 d-flex justify-content-end image-bg-card-1">
										<h2>
											{realtorDetails.referralBooking}
										</h2>
										<div>
											Referral
											<br />
											bookings (R12)
										</div>
									</div>
								</div>
								<div className="col-12 h-50">
									<div className="card card-primary p-4 mb-3 h-100">
										<div className="d-flex align-items-center">
											<h3 className="text-gray text-uppercase flex-fill mr-2 mb-0">
												Listing overview
											</h3>
											<span
												onClick={() => {
													setPropertyFilter(
														{
															sortColumn:
																"dateAdded",
															sortDirection:
																"desc",
															pageIndex: 1,
															pageSize: 10,
															searchKey: "",
															filters: [{ key: "status", value: "1", label: "Status" }]
														},
														() => {
															history.push(
																`/listings?realtorId=${realtorDetails.realtorId}`
															);
														}
													);
												}}
												className="text-primary pointer">
												View Listings &gt;
											</span>
										</div>
										<div className="d-flex mt-3">
											<div className="flex-fill">
												<span className="icon-inprocess text-lg">
													<span className="icon-path path1"></span>
													<span className="icon-path path2"></span>
													<span className="icon-path path3"></span>
												</span>
											</div>
											<div className="flex-fill text-right color-2">
												<i className="icon icon-property text-lg" />
											</div>
										</div>
										<div className="my-1">
											<ProgressBar
												percentage={
													(realtorDetails.inProcessPropertyCount /
														realtorDetails.totalListing) *
													100
												}
											/>
										</div>
										<div className="d-flex align-items-top">
											<div className="mr-3 flex-fill">
												<div>In Process</div>
												<h2>
													{
														realtorDetails.inProcessPropertyCount
													}
												</h2>
											</div>
											<div className="text-right">
												<div>Total Listing</div>
												<h2>
													{
														realtorDetails.totalListing
													}
												</h2>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}
				<RealtorListingCoverage
					data={realtorListingCoverage && realtorListingCoverageExists ? realtorListingCoverage : null}
					marketList={realtorMarketList}
					initialValue={selectedMarket}
					onChange={(e) =>
						e &&
						marketRefId !== e.marketRefId &&
						dispatch({
							subMarketType: e.type,
							marketRefId: e.value,
							selectedMarket: e,
						})
					}
					loggedInAs={loggedInAs}
				/>

				<CommunicationFeed
					getCommunicationFeed={getRealtorCommunicationFeed}
					realtor={realtorDetails && realtorDetailsExists ? realtorDetails : null}
					reloadCommunicationFeed={reloadCommunicationFeed}
				/>
			</div>

			{realtorDetails ? (
				<>
					<SidePanel
						show={showMailPanel}
						onHide={() => dispatch({ showMailPanel: false })}
						component={EmailPanel}
						className="create-template-panel create-template-panel-sm"
						componentProps={{
							dispatch,
							data: templateCompanyDetails,
							formValues: emailTemplateformValues,
							change,
							sendManualProspecting,
							realtorId: realtorDetails.realtorId,
							reloadCommunicationFeed,
							toggleMainLoader,
						}}
					/>

					<SidePanel
						show={showPostCardPanel}
						onHide={() => dispatch({ showPostCardPanel: false })}
						component={MailerPanel}
						className="create-template-panel create-template-panel-sm"
						componentProps={{
							dispatch,
							data: templateCompanyDetails,
							formValues: mailerTemplateformValues,
							change,
							sendManualProspecting,
							realtorId: realtorDetails.realtorId,
							reloadCommunicationFeed,
							toggleMainLoader,
						}}
					/>

					<SidePanel
						show={showSMSPanel}
						onHide={() => dispatch({ showSMSPanel: false })}
						component={SMSPanel}
						className="create-template-panel create-template-panel-sm"
						componentProps={{
							dispatch,
							sendManualProspecting,
							realtorId: realtorDetails.realtorId,
							reloadCommunicationFeed,
							toggleMainLoader,
						}}
					/>

					<SidePanel
						show={showEditPanel}
						onHide={() => dispatch({ showEditPanel: false })}
						component={EditPanel}
						componentProps={{
							dispatch,
							realtorId: realtorDetails.realtorId,
							onEditSubmit,
							onCancel,
							initialValues: realtorEditData,
						}}
					/>
				</>
			) : null}
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		emailTemplateformValues: getFormValues("emailTemplateForm")(state),
		mailerTemplateformValues: getFormValues("mailerTemplateForm")(state),
		userDetails: get(state, "Auth.userDetails", {}),
		realtorDetails: get(state, "Realtor.realtorDetails", null),
		realtorListingCoverage: get(state, "Realtor.realtorListingCoverage", null)
	};
};

const mapDispatchToProps = {
	toggleMainLoader,
	getRealtor,
	getRealtorListingCoverage,
	getRealtorMarketList,
	getRealtorCommunicationFeed,
	getTemplateCompanyDetails,
	sendManualProspecting,
	setPropertyFilter,
	getRealtorEditData,
	updateRealtorData,
	settingsToggleMainLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(RealtorDetails);
