import { get } from "lodash";

const rootURL = process.env.REACT_APP_ROOT_URL;
const LSVariable = process.env.REACT_APP_LS_VAR;

export default function fetchHandler(
	{
		url,
		method = "GET",
		actionType,
		headers,
		body,
		secure = true,
		fileUpload = false,
		signal = null,
	},
	successHandler = null,
	errorHandler = null
) {
	return (dispatch, getState) => {
		const controller = signal ? null : new AbortController();
		const abortSignal = signal || controller?.signal;

		const triggerSuccessHandler = (response) => {
			dispatch({
				type: actionType,
				payload: response,
			});
			return successHandler ? successHandler(response) : null;
		};

		const LSString = localStorage.getItem(LSVariable);
		const LSToken = LSString ? JSON.parse(LSString) : null;
		const stateToken = get(getState(), "Auth.accessToken", null);
		const accessToken = stateToken
			? stateToken.accessToken
			: LSToken
				? LSToken.accessToken
				: null;

		let headersData = {
			...headers,
			Accept: fileUpload ? "multipart/form-data" : "application/json",
			"Content-Type": fileUpload
				? "multipart/form-data"
				: "application/json",
		};

		if (secure) {
			headersData = {
				...headersData,
				Authorization: `Bearer ${accessToken}`,
			};
		}

		const request = fetch(`${rootURL}${url}`, {
			method,
			headers: {
				...headersData,
			},
			body,
			signal: abortSignal,
		});

		let status = null;
		request
			.then((data) => {
				status = data.status;
				return data.json();
			})
			.then((response) => {
				if (status && status > 399) {
					return errorHandler ? errorHandler(response) : null;
				} else {
					return triggerSuccessHandler(response);
				}
			})
			.catch((err) => {
				// Don't trigger error handler for aborted requests
				if (err.name === 'AbortError') {
					return;
				}

				const errorObj = {
					error: {
						url: `${rootURL}${url}`,
						code: "FETCH_FAILED",
						message: err,
					},
				};
				return errorHandler ? errorHandler(errorObj) : null;
			});

		return {
			type: actionType,
			payload: request,
			abort: controller ? () => controller.abort() : null,
		};
	};
}