import React from "react";
import { Field } from "redux-form";
import FormGroup from "../../utils/formGroup";
import { Button } from "../../utils/uiElements";
import { get } from "lodash";

const passwordLinkInfo = {
  1: {
    text: "Gmail",
    link: "https://support.google.com/accounts/answer/185833?hl=en",
  },
  2: {
    text: "Yahoo",
    link: "https://help.yahoo.com/kb/generate-third-party-passwords-sln15241.html",
  },
  4: {
    text: "Office 365",
    link: "https://docs.microsoft.com/en-us/azure/active-directory/user-help/multi-factor-authentication-end-user-app-passwords",
  },
  3: {
    text: "Outlook",
    link: "https://docs.microsoft.com/en-us/azure/active-directory/user-help/multi-factor-authentication-end-user-app-passwords",
  },
};
const EmailSettings = ({
  userSettings,
  fields,
  formValues,
  hideOnMockID,
  testApiConnectionAction,
}) => {
  let office = fields?.emailAccountTypeId?.defaultValue.value === 4;
  return userSettings ? (
    <>
      <h3 className="color-1 text-uppercase">Email Settings</h3>
      <div className="row">
        <Field component={FormGroup} {...fields.emailAccountTypeId} />
        {formValues &&
        formValues.emailSettings &&
        formValues.emailSettings.emailAccountTypeId ? (
          <Button
            className="btn btn-cell btn-light notification-icon"
            type="button"
            onClick={() => {
              window.open(
                formValues
                  ? get(
                      passwordLinkInfo,
                      `${formValues.emailSettings.emailAccountTypeId.value}.link`,
                      ""
                    )
                  : null,
                "_blank"
              );
            }}
            value={<i className="icon icon-info text-gray" />}
            tooltip={`${
              formValues?.emailSettings?.emailAccountTypeId?.value === 4
                ? ""
                : "Click to know more about creating your"
            } ${
              formValues?.emailSettings?.emailAccountTypeId?.value !== 4 &&
              formValues
                ? get(
                    passwordLinkInfo,
                    `${formValues.emailSettings.emailAccountTypeId.value}.text`,
                    ""
                  )
                : ""
            } ${
              formValues?.emailSettings?.emailAccountTypeId?.value === 4
                ? "Contact Moovsoon Administrator for details on setting up. Moovsoon will use this account to send and receive emails from a homeowner or realtor"
                : "App password. Moovsoon will use this password to send and receive emails to a realtor or homeowner from your account."
            }`}
          ></Button>
        ) : null}
      </div>
      {hideOnMockID ? null : (
        <div className="row mb-4">
          <Field component={FormGroup} {...fields.userName} />
          {office ? (
            <>
              <Field component={FormGroup} {...fields.clientId} />
              <Field component={FormGroup} {...fields.clientSecretValue} />
              <Field component={FormGroup} {...fields.appTenantId} />
            </>
          ) : (
            <Field component={FormGroup} {...fields.password} />
          )}

          {formValues &&
          formValues.emailSettings &&
          formValues.emailSettings.emailAccountTypeId ? (
            <div className="col-3 pt-1">
              {formValues &&
              formValues.emailSettings &&
              ((!office &&
                formValues.emailSettings.userName &&
                formValues.emailSettings.password) ||
                (formValues.emailSettings.userName &&
                  formValues.emailSettings.clientSecretValue &&
                  formValues.emailSettings.clientId &&
                  formValues.emailSettings.appTenantId)) ? (
                <span className="ml-0">
                  <button
                    onClick={() => {
	                  //console.log("connecting...");
                      testApiConnectionAction(fields, formValues);
                    }}
                    type="button"
                    className="btn btn-sm btn-default"
                  >
                    Test Connection
                  </button>

                </span>
              )  : null}
            </div>
          ) : null}
        </div>
      )}
    </>
  ) : null;
};

export default EmailSettings;
