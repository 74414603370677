import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
// Logger with default options
import { createLogger } from "redux-logger";

import Reducer from "./reducers";

const logger = createLogger({
	collapsed: true,
});

const middlewares = [thunk];

if (process.env.NODE_ENV === "development") {
	console.log("redux-logging", process.env.REACT_APP_LOGGER);
	if (process.env.REACT_APP_LOGGER === "enable") {
		middlewares.push(logger);
	}
} else {
	console.log("redux-logging-disable");
}

export const store = createStore(Reducer, applyMiddleware(...middlewares));
