import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../realtors.scss";

import { get } from "lodash";
import { Pagination, Table } from "../../../partials/table/table";
import SearchField from "../../../utils/searchTable";
import TableLoader from "../../../utils/tableLoader";
import { columns as columnsFn } from "./helper";

import {
  getRealtors,
  setFilter,
  toggleMainLoader,
} from "../../../store/actions";
import { errorMessage } from "../../../utils/errorMessage";


const RealtorListing = ({
  location,
  history,
  toggleMainLoader,
  userDetails,
  getRealtors,
  realtorList,
  recordsTotal,
  setFilter,
  filter,
}) => {
  const [isFromRealtorView, setIsFromRealtorView] = useState(location.state?.fromRealtorView)

  useEffect(() => {
    if (isFromRealtorView && realtorList ) {
      setIsFromRealtorView(false)
      return;
    }
    const successHandler = () => {
      toggleMainLoader(false);
    };
    const errorHandler = (event) => {
      errorMessage(event);
      toggleMainLoader(false);
    };
    setIsFromRealtorView(false)
    toggleMainLoader(true);
    getRealtors(
      {
        pageIndex: filter.pageIndex,
        pageSize: filter.pageSize,
        sortColumn: filter.sortColumn,
        sortDirection: filter.sortDirection,
        searchKey: filter.searchKey,
        withRank: filter.withRank,
        userId: userDetails.userId,
      },
      successHandler,
      errorHandler
    );
  }, [filter, userDetails, toggleMainLoader, getRealtors]);

  const onSelect = (row) => {
    history.push(`/realtors/${row.realtorId}/view`);
  };

  const columns = columnsFn({
    onSelect,
    userDetails,
    withRank: filter.withRank
  });

  const onSort = (e) => {    
    return e.by !== filter.sortColumn || e.order !== filter.sortDirection
      ? setFilter({
          ...filter,
          sortColumn: e.by,
          sortDirection: e.order,
          pageIndex: 1
        })
      : null;
  };

  const onSearch = (searchKey) => {
    setFilter({ ...filter, searchKey, pageIndex: 1 });
  };

  return (
    <section className="moovsoon-page-container">
      <div className="moovsoon-page-body">
        <div className="d-flex align-items-center mb-3">
          <h2 className="mr-3 mb-0">Realtors</h2>
          <SearchField
            initialValue={filter.searchKey}
            onSearch={onSearch}
            className="flex-fill mr-3 form-group mb-0"
            placeholder="Enter your query"
          />
        </div>
        {realtorList && realtorList.length ? (
          <div>
            <Table
              columns={columns}
              data={realtorList}
              onSort={onSort}
              sortBy={filter.sortColumn}
              sortDir={filter.sortDirection}
            />
            <Pagination
              total={recordsTotal}
              count={filter.pageSize}
              page={filter.pageIndex}
              onChange={(pageIndex) => setFilter({ ...filter, pageIndex })}
            />
          </div>
        ) : null}
        <TableLoader list={realtorList} />
      </div>
    </section>
  );
};
const mapStateToProps = (state) => {
  return {
    userDetails: get(state, "Auth.userDetails", {
      sortColumn: "ranking",
      sortDirection: "asc",
      pageIndex: 1,
      pageSize: 10,
      searchKey: "",
      withRank: true
    }),
    filter: get(state, "Realtor.filter", {}),
    realtorList: get(state, "Realtor.realtorList", null),
    recordsTotal: get(state, "Realtor.recordsTotal", 0)
  };
};
const mapDispatchToProps = {
  toggleMainLoader,
  getRealtors,
  setFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(RealtorListing);
