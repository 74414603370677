import { REALTOR } from "../types";

export default (
  state = {
    realtorList: null,
    recordsTotal: 0,
    filter: {
      sortColumn: "",
      sortDirection: "",
      pageIndex: 1,
      pageSize: 10,
      searchKey: "",
      withRank: true,
    },
    realtorDetails: null,
    realtorListingCoverage: null
  },
  action
) => {
  switch (action.type) {
    case REALTOR.GET_REALTOR_LISTING_COVERAGE:
      return {
        ...state,
        realtorListingCoverage: action.payload && action.payload.result ? action.payload.result : null
      }
    case REALTOR.GET_REALTOR_DETAILS:
      return {
        ...state,
        realtorDetails: action.payload && action.payload.result ? action.payload.result : null
      }
    case REALTOR.GET_REALTORS:
      return {
        ...state,
        realtorList: action.payload && action.payload.result ? action.payload.result.data : [],
        recordsTotal: action.payload && action.payload.result ? action.payload.result.recordsTotal : [],
      };
    case REALTOR.SET_FILTER:
      return {
        ...state,
        filter: action.payload ? action.payload : {},
      };
    case REALTOR.REALTOR_PRELOADER:
      return {
        ...state,
        realtorPreloader: action.payload,
      };
    default:
      return state;
  }
};
